/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = ({ title }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h1 style={{ margin: "0" }}>
          <a
            style={{
              textDecoration: "none",
              boxShadow: "none",
              color: "unset",
              cursor: "pointer",
            }}
            href="/"
          >
            {title}
          </a>
        </h1>
      </div>
      <div
        style={{
          display: `flex`,
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "10px", fontStyle: "italic" }}>by</div>
        <a
          style={{ textDecoration: "none", boxShadow: "none" }}
          href={"https://driftbyte.xyz"}
        >
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author.name}
            href={"https://driftbyte.xyz"}
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
              borderRadius: `100%`,
              textDecoration: "none",
            }}
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        </a>
      </div>
    </div>
  )
}

export default Bio
